<template>
  <div class="game-content-mb">
    <div class="game-content-mb__header">
      <slot name="header" />
    </div>
    <div
      class="game-content-mb__main"
      :class="{ [$route.name]: $route.name }"
    >
      <div
        class="game-content-mb__main--navigation"
      >
        <slot name="mainNavigation" />
      </div>
      <div
        id="game-content"
        class="game-content-mb__main--content"
      >
        <slot name="main" />
      </div>
    </div>
    <div class="game-content-mb__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped src="~/assets/scss/components/mobile/layouts/game/index.scss"></style>
